import React from 'react'

const Logo =  () => {
    
    let fillColor =  '#fff';

    return (
        <>
        <svg id="Krasniy_report" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 495.17 260.79">
            <title>Krasniy Report</title>
            <path fill={fillColor} d="M2.54,101.4v-4l11.3-5.2V22.23L2.54,17V13H46.08v4l-11.31,5.2V92.17l11.31,5.2v4Zm65.38,0L36.72,56,69.61,21.71,61.81,17V13h33v4l-11.7,5.2L56.61,48.88,86.9,92.17l11.18,5.2v4Z"/>
            <path fill={fillColor} d="M142.41,55.25l-3.64-9.49h-1.3A28.73,28.73,0,0,0,133,46a27.2,27.2,0,0,0-3.84,1V92.17l13.78,5.2v4H97.43v-4l11.31-5.2V47.45L97.43,44.07V39.78l31.72-5.33v7.28c2.25-1.3,4.25-2.38,6-3.25s3.29-1.58,4.68-2.15a25.94,25.94,0,0,1,3.77-1.23,15,15,0,0,1,3.38-.39,20,20,0,0,1,2.41.13,10.56,10.56,0,0,1,2.21.52,19,19,0,0,1,2.34,1L156.84,38V55.25Z"/>
            <path fill={fillColor} d="M227.82,97.76c-.69.26-1.54.54-2.53.84s-2,.59-2.93.85-2.12.65-3.25.91a52.74,52.74,0,0,1-5.91,1.69,27.39,27.39,0,0,1-5.92.65,11.36,11.36,0,0,1-5.91-1.3q-2.15-1.31-4-5.07a18.49,18.49,0,0,1-2.21,1.17c-.69.43-1.45.87-2.27,1.3s-1.71.87-2.67,1.3a25.76,25.76,0,0,1-5.78,2.08,30.29,30.29,0,0,1-6.05.52,24.37,24.37,0,0,1-7.93-1.24,18.33,18.33,0,0,1-6.24-3.5,16.32,16.32,0,0,1-5.59-12.55,14.91,14.91,0,0,1,4-10.53q4-4.29,11.11-5.72c1.22-.26,2.43-.5,3.64-.72s2.58-.43,4.1-.65,3.18-.41,5-.58,4-.39,6.37-.65l4-.39v-15c0-4.16-.86-7-2.6-8.45a8.39,8.39,0,0,0-2.27-1,14,14,0,0,0-3.32-.32c-.78,0-1.51,0-2.21.06a24.34,24.34,0,0,0-2.47.33l-6.11,12H164.51V40.43a21,21,0,0,0,3-.78c1-.26,2.06-.54,3.32-.85s2.62-.67,4.09-1.1c2.08-.52,4-1,5.59-1.37s3.19-.71,4.62-1a36.92,36.92,0,0,1,4.29-.52c1.43-.09,3-.13,4.61-.13a41.79,41.79,0,0,1,8.26.71,28.42,28.42,0,0,1,6.43,2.15q4.56,2.2,6.24,5.39c1.13,2.13,1.69,5,1.69,8.78V90c.09.69.91,1.26,2.47,1.69l8.71,2.21ZM196.88,71.89l-6.63.91q-6.5.92-9.16,3.18t-2.67,7q0,9.23,11.31,9.23c1,0,2,0,2.93-.07a25.46,25.46,0,0,0,3.44-.45l.78-.13Z"/>
            <path fill={fillColor} d="M282.16,82.29a18.42,18.42,0,0,1-1.88,8.32A18.61,18.61,0,0,1,275,97a26.12,26.12,0,0,1-8.19,4.17,34.94,34.94,0,0,1-10.47,1.49,56.35,56.35,0,0,1-6.11-.39,74.17,74.17,0,0,1-7.67-1.3l-2.66-.65-3.19-.78c-1.21-.35-2.51-.65-3.9-.91V83.2h10.66l6.11,12a7.35,7.35,0,0,0,1.56.26h1.56a28.7,28.7,0,0,0,4.36-.33,10.87,10.87,0,0,0,3.77-1.23,7.43,7.43,0,0,0,2.66-2.54,8,8,0,0,0,1-4.35,6.23,6.23,0,0,0-1.56-4.49,36.3,36.3,0,0,0-5.72-4.35l-10.92-7c-1.65-1-3-1.88-4.09-2.54s-2-1.23-2.73-1.75a18.36,18.36,0,0,1-1.89-1.5,9.75,9.75,0,0,1-1.43-1.62A15.72,15.72,0,0,1,232.76,54a16.9,16.9,0,0,1,1.82-7.74,18.33,18.33,0,0,1,5-6.11,23.87,23.87,0,0,1,7.67-4,32.25,32.25,0,0,1,9.81-1.43,53.91,53.91,0,0,1,7,.52q4,.52,7.34,1.17L274,37c1,.22,2,.42,2.92.59s2.12.52,3.25.78V52.78H269.68l-6-10.66a14.23,14.23,0,0,0-1.82-.13h-1.3a11,11,0,0,0-6.89,2,6.51,6.51,0,0,0-2.6,5.4,5.79,5.79,0,0,0,1.43,4,21.89,21.89,0,0,0,5.2,3.84l11.83,7.15a69.23,69.23,0,0,1,6,4.16,20.17,20.17,0,0,1,4,4.16A17,17,0,0,1,282.16,82.29Z"/>
            <path fill={fillColor} d="M334.55,101.4v-4l7.93-5.2V62.4c0-1.91,0-3.53-.06-4.88a23.37,23.37,0,0,0-.33-3.37,12.67,12.67,0,0,0-.65-2.35,12.88,12.88,0,0,0-1-2,7.77,7.77,0,0,0-3.51-3.06,15.91,15.91,0,0,0-6.24-1c-1.39,0-2.84.06-4.36.2s-3.35.36-5.52.71v45.5l8.45,5.2v4H289.05v-4l11.31-5.2V47.45l-11.31-3.51V39.78l31.72-5.33v7.28q3.38-1.42,6.24-2.54c1.91-.73,3.94-1.45,6.11-2.14,1.65-.52,3-.93,4-1.24s2-.54,2.73-.71a16.41,16.41,0,0,1,2-.33c.56,0,1.19-.06,1.88-.06a22,22,0,0,1,7.67,1.5,15.42,15.42,0,0,1,6.5,4.35,20.18,20.18,0,0,1,2.34,3.38,18.68,18.68,0,0,1,1.5,3.71,25.54,25.54,0,0,1,.84,4.61q.25,2.6.26,6.11v33.8l11.31,5.2v4Z"/>
            <path fill={fillColor} d="M376,101.4v-4l11.31-5.2V47.45L376,43.94V39.78l31.72-5.33V92.17l11.18,5.2v4Zm33-83.72q0,4.55-2.8,6.57t-9,2q-6.24,0-9-2t-2.79-6.37q0-8.19,11.83-8.19T409,17.68Z"/>
            <path fill={fillColor} d="M481.32,45.37l-27.43,65.39a56.38,56.38,0,0,1-4.68,9.1,25.36,25.36,0,0,1-5.46,6.11,19.47,19.47,0,0,1-6.63,3.38,30,30,0,0,1-8.19,1,46.11,46.11,0,0,1-5.66-.33,42.63,42.63,0,0,1-6-1.23V115.31h12.35l2.34,8.58a15.87,15.87,0,0,0,4.87-1.89,13.69,13.69,0,0,0,3.51-3,20,20,0,0,0,2.6-4.1c.74-1.51,1.45-3.14,2.15-4.87l3.25-8.45L423.73,45.37l-7.93-5.2v-4h37.57v4l-8.45,5.2,9.23,25.48c.69,1.91,1.21,3.32,1.56,4.22s.65,1.65.91,2.21l.65,1.43a18.15,18.15,0,0,1,.65,1.89l13.39-35.23-8.58-5.2v-4H489v4Z"/>
            <path fill={fillColor} d="M63.38,221.4l-27.7-42.12H33.21v32.89l11.18,5.2v4H2.54v-4l11.3-5.2V142.1L2.54,137v-4H45.43a65.73,65.73,0,0,1,13.39,1.1,23.47,23.47,0,0,1,8.72,3.71,19.09,19.09,0,0,1,6,7.28,21.91,21.91,0,0,1,2.21,9.75q0,9.1-4.93,14.95t-14.44,8.06l23.53,34.32,11.05,5.2v4Zm-8.72-65.52q0-7.41-3.31-11.9T40,139.5H33.21V174h7.15q6.9,0,10.6-4.75T54.66,155.88Z"/>
            <path fill={fillColor} d="M110.82,188a40.84,40.84,0,0,0,.91,9.42,21.58,21.58,0,0,0,3.51,7.35q5.85,8.46,18.07,8.45a48.07,48.07,0,0,0,6.7-.52q3.7-.52,10.07-1.69l1.17,5.59-3.51,1.17c-1,.35-2,.67-3.05,1s-2,.55-2.67.72q-11.43,3.24-19,3.25a38.49,38.49,0,0,1-13.58-2.27,28.1,28.1,0,0,1-16.9-16.91,38.22,38.22,0,0,1-2.28-13.58A39.36,39.36,0,0,1,93.4,174.4a32.39,32.39,0,0,1,9-12.28,31.74,31.74,0,0,1,9.75-5.59,37.12,37.12,0,0,1,12.22-1.82,32.12,32.12,0,0,1,14.11,2.86,20.56,20.56,0,0,1,9,8.19,26.66,26.66,0,0,1,2.15,4.22,25.59,25.59,0,0,1,1.3,4.68q.45,2.53.71,5.79T152,188ZM132,179.28a77.6,77.6,0,0,0-.78-8.06,20,20,0,0,0-1.56-5.46,7.12,7.12,0,0,0-3-3.45,10.62,10.62,0,0,0-4.88-1q-5.71,0-8.12,4.48c-1.61,3-2.49,8.09-2.67,15.28Z"/>
            <path fill={fillColor} d="M235.36,185.78a36.59,36.59,0,0,1-2.92,14.69,36.17,36.17,0,0,1-8,11.7,36.58,36.58,0,0,1-12,7.73,40.15,40.15,0,0,1-15.15,2.8c-1.64,0-3,0-4.16-.06a30.29,30.29,0,0,1-4.68-.72v19.89l13.91,5.2v4h-45.5v-4l11.18-5.2V167.45l-11.18-3.51v-4.16l31.59-5.33v7q2.34-1,4.68-1.89l4.16-1.49c1.91-.69,3.53-1.26,4.88-1.69s2.51-.78,3.51-1a19.29,19.29,0,0,1,2.66-.52,21,21,0,0,1,12.09,2.27,24.9,24.9,0,0,1,7.87,6.57,31.75,31.75,0,0,1,5.2,9.81A38.89,38.89,0,0,1,235.36,185.78Zm-21.19,2.73q0-8.19-1.62-12.74a16.91,16.91,0,0,0-4.1-6.83,10.27,10.27,0,0,0-5.52-2.72,39.35,39.35,0,0,0-5.92-.46,32.7,32.7,0,0,0-3.83.19c-1.09.13-2.67.42-4.75.85V215a18.6,18.6,0,0,0,3,.26h2.47a17.64,17.64,0,0,0,14.69-7Q214.18,201.25,214.17,188.51Z"/>
            <path fill={fillColor} d="M314,187.6a38.94,38.94,0,0,1-2.73,15,32,32,0,0,1-7.6,11.24,33.49,33.49,0,0,1-11.57,7.09,42.14,42.14,0,0,1-14.63,2.47,45.29,45.29,0,0,1-15-2.34,31.78,31.78,0,0,1-11.38-6.7,28.75,28.75,0,0,1-7.15-10.53,36.69,36.69,0,0,1-2.47-13.71,37.5,37.5,0,0,1,2.8-14.88A31.79,31.79,0,0,1,252,164.07a34,34,0,0,1,11.7-7,43.65,43.65,0,0,1,14.69-2.4,44.39,44.39,0,0,1,13.91,2.14,32.06,32.06,0,0,1,11.31,6.38,30.39,30.39,0,0,1,7.61,10.33A33.45,33.45,0,0,1,314,187.6Zm-21.19,2.34a80,80,0,0,0-.52-8.58,34.53,34.53,0,0,0-2.21-9.1,20.84,20.84,0,0,0-4.68-7.28,10.72,10.72,0,0,0-7.93-3q-7.28,0-11.11,6.57t-3.84,18.78a72.64,72.64,0,0,0,.59,8.51,39.53,39.53,0,0,0,2.21,9.23,20.14,20.14,0,0,0,4.61,7.35,10.58,10.58,0,0,0,7.8,3,11.69,11.69,0,0,0,7.8-2.53,16.93,16.93,0,0,0,4.62-6.37,30,30,0,0,0,2.14-8.33A71.29,71.29,0,0,0,292.82,189.94Z"/>
            <path fill={fillColor} d="M363.93,175.25l-3.64-9.49H359a28.73,28.73,0,0,0-4.48.26,27.2,27.2,0,0,0-3.84,1v45.11l13.78,5.2v4H319v-4l11.31-5.2V167.45L319,164.07v-4.29l31.72-5.33v7.28c2.25-1.3,4.25-2.38,6-3.25s3.29-1.58,4.68-2.15a25.56,25.56,0,0,1,3.77-1.23,15,15,0,0,1,3.38-.39,20,20,0,0,1,2.41.13,10.56,10.56,0,0,1,2.21.52,19,19,0,0,1,2.34,1l2.92,1.56v17.29Z"/>
            <path fill={fillColor} d="M421.39,220.36a56.45,56.45,0,0,1-9,1.82,66.47,66.47,0,0,1-7,.52,34,34,0,0,1-6.63-.65,15.76,15.76,0,0,1-5.46-2.08A11.12,11.12,0,0,1,389,214.9a22.44,22.44,0,0,1-1.43-8.84v-42h-9.88v-5.33q6.24-2.09,9.56-3.58a14.84,14.84,0,0,0,4.87-3.18,13.59,13.59,0,0,0,1.43-1.88l2-3a39.71,39.71,0,0,0,2.08-3.44c.65-1.21,1.23-2.34,1.75-3.38H408v15.86h20.54v7.93H408v37.44a32.22,32.22,0,0,0,.33,5.26,7.67,7.67,0,0,0,1.23,3.19c1.65,2.17,4.46,3.25,8.45,3.25a46.93,46.93,0,0,0,4.81-.26q2.48-.25,5.59-.78l1.3,6Z"/>
            <path fill={fillColor} d="M429.32,240.25v-9.36h63.31v9.36Z"/>
        </svg>
    </>
    )
       
};

export default Logo 